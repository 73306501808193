import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxGallery, { PuxGalleryType } from '../PuxGallery/PuxGallery'

export interface WidgetPuxGalleryType extends WidgetBase, PuxGalleryType {
  contentType: 'PuxGallery'
}

const WidgetPuxGallery: FunctionComponent<WidgetPuxGalleryType> = (props) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata}>
    <PuxGallery
      contentItemId={props.contentItemId}
      displayText={props.displayText}
      galleryList={props.galleryList}
      galleryItemsToLightbox={props.galleryItemsToLightbox}
    />
  </WidgetContainer>
)

export default WidgetPuxGallery
